import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';

const query = graphql`
  query ErrorPage {
    errorPage: markdownRemark(fileAbsolutePath: { regex: "*/error-page/" }) {
      id
      html
    }
  }
`;

const Wrapper = styled.div`
  margin: 6vmin 6vmax;
`;

const ErrorPage = () => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const {
          errorPage: { html }
        } = data;

        return <Wrapper dangerouslySetInnerHTML={{ __html: html }} />;
      }}
    />
  );
};

export default ErrorPage;
