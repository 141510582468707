import React from 'react';
import styled from 'styled-components';
import { color, media } from '../../theme/variables';
import Button from '../Common/Button';
import PostCard from './PostCard';

const ListContainer = styled.section`
  background: ${color.light};
  padding: 2rem;

  ${media.tablet`
    padding: 6vmax;
  `}

  ${media.desktop`
    padding: 4vmax;
  `}
`;

const HeaderWrapper = styled.div`
  text-align: center;

  h1 {
    color: ${color.primary};
  }

  h2 {
    color: ${color.primary};
  }

  p {
    color: ${color.dark};
    margin-bottom: 1rem;
  }
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: auto;
  margin-bottom: 2rem;
  max-width: 400px;

  ${media.tablet`  
    flex-flow: row nowrap;
    justify-content: space-between;
    max-width: 1200px;
  `};
`;

const ActionsWrapper = styled.div`
  text-align: center;
`;

export default props => {
  const {
    list,
    htmlHeader,
    description,
    title,
    buttonText = 'Ver todos',
    buttonUrl = 'servicios',
    showDescription
  } = props;

  return (
    <ListContainer>
      {htmlHeader ? (
        <HeaderWrapper dangerouslySetInnerHTML={{ __html: htmlHeader }} />
      ) : (
        <HeaderWrapper>
          <h1>{title}</h1>
          {description && <p>{description}</p>}
        </HeaderWrapper>
      )}
      <ItemsWrapper>
        {list &&
          list.length > 0 &&
          list.map(post => (
            <PostCard
              showDescription={showDescription}
              key={post.node.id}
              post={post}
            />
          ))}
      </ItemsWrapper>
      <ActionsWrapper>
        <Button navigateTo={buttonUrl}>{buttonText}</Button>
      </ActionsWrapper>
    </ListContainer>
  );
};
