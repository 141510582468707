import React from 'react';
import 'prismjs/themes/prism-okaidia.css';
import styled from 'styled-components';
import { AsyncComponent, HtmlBody, Button } from '../Common';
import PostHeader from './PostHeader';
import PostTags from './PostTags';
import CTAForm from './CTAForm';
import { color, media } from '../../theme';
import PostTestimonials from './PostTestimonials';
import config from '../../../config';
import { getRandomInt } from '../../utils';

const Share = AsyncComponent(() =>
  import('../Common/Share')
    .then(module => {
      return module.default;
    })
    .catch(error => console.error(error))
);

const Article = styled.article`
  display: grid;
  grid-template-columns: minmax(4vmin, auto) minmax(auto, 700px) minmax(
      4vmin,
      auto
    );
  grid-template-areas: 'header header header' '. title  .' 'share content .' '. footer .';
  grid-gap: 1vmax;
  margin-bottom: 6vmin;
`;

const Header = styled.header`
  grid-area: header;
  max-height: 60vmin;
  position: relative;
`;

const PostTitle = styled.div`
  grid-area: title;
  z-index: 2;
  grid-row: header-start / header-end;
  align-self: end;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  margin-bottom: 4vmin;

  button {
    flex: 1 1 20%;
    background: ${color.primary};
    color: ${color.lightest};
    padding: 1vmin 3vmax;

    &:hover {
      background: ${color.secondaryLighter};
      border-color: ${color.secondaryLighter};
    }
    :active {
      background: ${color.secondaryLightest};
      border-color: ${color.secondaryLightest};
    }
  }

  h1 {
    flex: 1 1 auto;
    color: ${color.lightest};
    font-size: 2rem;
  }

  ${media.tablet`
    flex-flow: row nowrap;

    h1 {
      margin-bottom: 0;
      font-size: 3rem;
    }
  `};
`;

const Body = styled.section`
  grid-area: content;
`;

const FixedShare = styled(Share)`
  grid-area: share;
  display: block;

  ${media.desktop`
    margin-left: auto !important;
    margin-right: auto !important;
  `};
`;

const HorizontalShare = styled(Share)`
  margin-bottom: 4vmax;
`;

const Footer = styled.footer`
  grid-area: footer;
  background: ${color.secondary}f0;
  padding: 3vmax;
  align-self: flex-end;
  z-index: 2;
  color: ${color.lightest};
`;

const Post = props => {
  if (props.post.frontmatter.cover === null) {
    console.error(props.post.fields.slug);
    return null;
  }

  const {
    post,
    post: {
      html,
      frontmatter: {
        title,
        category,
        service,
        cover: { childImageSharp }
      }
    }
  } = props;

  function handleScrollTo() {
    const emailInput = document.getElementById('email');
    if (!emailInput) {
      // If email input couldn't be found, scroll to bottom anyway
      window.scrollTo(0, document.body.scrollHeight);
      return;
    }
    emailInput.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest'
    });
    emailInput.focus();
  }

  const randomTestimonial = {
    ...config.testimonials[getRandomInt(0, config.testimonials.length - 1)]
  };

  return (
    <Article>
      <Header>
        <PostHeader title={title} childImageSharp={childImageSharp} />
      </Header>
      <PostTitle>
        <h1>{title}</h1>
        <Button onClick={() => handleScrollTo()}>Comenzar</Button>
      </PostTitle>
      <FixedShare fixed post={post} />
      <Body>
        <PostTags category={category} service={service} />
        <HtmlBody html={html} />
        <HorizontalShare
          horizontal
          text="Comparte este artículo:"
          post={post}
        />
        {config.testimonials.length > 0 && (
          <PostTestimonials {...randomTestimonial} />
        )}
      </Body>
      <Footer>
        <CTAForm title={title} />
      </Footer>
    </Article>
  );
};

export default Post;
