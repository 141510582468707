import React from 'react';
import Slider from 'react-animated-slider';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { media } from '../../theme';
import 'react-animated-slider/build/horizontal.css';
import './styles.css';

const SliderWrapper = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 95vh;

  ${media.tablet`
    padding-top: 25vh;
  `};
`;

const SliderHero = ({ slides, autoplay }) => {
  function handleClick(url) {
    navigate(url);
  }

  return (
    <SliderWrapper>
      <Slider className="slider-wrapper" autoplay={autoplay} touchDisabled>
        {slides &&
          slides.length > 0 &&
          slides.map(
            ({ title, description, buttonPrimary, buttonSecondary }, index) => (
              <div className="slider-content" key={index}>
                <div className="inner">
                  <h1 className="title">{title}</h1>
                  <p className="description">{description}</p>
                  <div className="footer">
                    {buttonSecondary && (
                      <button
                        className="btn-secondary"
                        onClick={() => handleClick(buttonSecondary.url)}
                      >
                        {buttonSecondary.text}
                      </button>
                    )}
                    {buttonPrimary && (
                      <button
                        className="btn-primary"
                        onClick={() => handleClick(buttonPrimary.url)}
                      >
                        {buttonPrimary.text}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )
          )}
      </Slider>
    </SliderWrapper>
  );
};

export default SliderHero;
