import {
  color,
  font,
  spacing,
  screenSizes,
  media,
  menu,
  animation
} from './variables';
export * from './variables';

export default {
  color,
  font,
  spacing,
  screenSizes,
  media,
  menu,
  animation
};
