import React from 'react';
import styled from 'styled-components';
import { color, media } from '../../theme/variables';
import { Input, Textarea, Button } from '../Common';
import { ChatSharp, PhoneSharp, EmailSharp } from '@material-ui/icons';
import { graphql, StaticQuery } from 'gatsby';
import { encode } from '../../utils';

const queryFormMessages = graphql`
  query ContactForm {
    successMessage: markdownRemark(
      fileAbsolutePath: { regex: "*/success-message/" }
    ) {
      id
      html
    }
    failMessage: markdownRemark(
      fileAbsolutePath: { regex: "*/fail-message/" }
    ) {
      id
      html
    }
  }
`;

const ContactWrapper = styled.section`
  padding: 2rem;
  margin-bottom: 6vmin;
  display: flex;
  flex-flow: column wrap;
  align-items: stretch;

  ${media.tablet`
    flex-flow: row nowrap;  
    padding: 6vmax 10vmax;
  `};

  ${media.desktop`
    padding: 4vmax 10vmax;
  `};
`;

const FormContainer = styled.div`
  ${media.tablet`
    flex: 1 1 60%;
    align-self: flex-start;
    margin-right: 6vw;
  `}
`;

const Form = styled.form`
  font-weight: 300;
`;

const InputsContainer = styled.div`
  margin-bottom: 2vmax;
`;

const Header = styled.header`
  margin-bottom: 2vmax;
`;

const Title = styled.h1`
  color: ${color.dark};
`;

const Subtitle = styled.h2`
  color: ${color.primary};
`;

const Footer = styled.footer`
  ${media.tablet`
    text-align: right;
  `}

  button {
    width: 100%;
    background: ${color.primary};
    color: ${color.lightest};
    padding: 1vmax 4vmax;

    &:hover {
      background: ${color.secondaryLighter};
      border-color: ${color.secondaryLighter};
    }
    &:active {
      background: ${color.secondaryLightest};
      border-color: ${color.secondaryLightest};
    }

    ${media.desktop`
      width: auto;
      padding: 1vmax 3vmax
    `}
  }
`;

const Aside = styled.aside`
  p {
    margin-bottom: 1rem;
  }

  ${media.tablet`
    flex: 1 1 40%;
    align-self: flex-start;
  `}
`;

const ContactInfo = styled.div`
  color: ${color.grey};
  font-weight: 300;

  p {
    margin: 0;
  }

  a {
    color: ${color.grey};
  }

  svg {
    vertical-align: middle;
    margin-right: 0.4rem;
    padding-bottom: 2px;
  }
`;

const ContactIcon = styled(ChatSharp)`
  display: none !important;

  ${media.tablet`
    display: block !important;
    font-size: 8rem !important;
    margin-bottom: 4vh;
  
    path {
      color: ${color.primaryLightest};
    }
  `}
`;

const MessageContainer = styled.div`
  min-height: 50vh;
`;

export default class ContactForm extends React.Component {
  state = {
    isSubmitted: false,
    isError: false
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        name: form.elements.name.value,
        email: form.elements.email.value,
        message: form.elements.message.value
      })
    })
      .then(() => this.handleSuccess())
      .catch(error => this.handleError(error));
  };

  handleSuccess = () => {
    this.setState({ isSubmitted: true });
  };

  handleError = error => {
    console.error(error);
    this.setState({ isError: true });
  };

  resetForm() {
    this.setState({ isSubmitted: false, isError: false });
  }

  render() {
    const { mailto, phoneNumberUrl, phoneNumberText } = this.props;
    const { isSubmitted, isError } = this.state;

    return (
      <StaticQuery
        query={queryFormMessages}
        render={data => {
          const {
            successMessage: { html: htmlSuccess },
            failMessage: { html: htmlFail }
          } = data;

          return (
            <ContactWrapper>
              <FormContainer>
                {isError ? (
                  <MessageContainer
                    dangerouslySetInnerHTML={{ __html: htmlFail }}
                  />
                ) : isSubmitted ? (
                  <MessageContainer
                    dangerouslySetInnerHTML={{ __html: htmlSuccess }}
                  />
                ) : (
                  <Form
                    name="contacto"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                  >
                    <Header>
                      <Title>Iniciemos la conversación</Title>
                      <Subtitle>¡Solo un paso más hacia el cambio!</Subtitle>
                    </Header>
                    <InputsContainer>
                      <input type="hidden" name="form-name" value="contact" />
                      <Input
                        name="name"
                        id="name"
                        placeholder="Nombre"
                        required
                      />
                      <Input
                        name="email"
                        id="email"
                        placeholder="Correo electrónico"
                        type="email"
                        required
                      />
                      <Textarea
                        name="message"
                        id="message"
                        placeholder="Mensaje"
                        required
                      />
                    </InputsContainer>
                    <Footer>
                      <Button type="submit" onClick={() => {}}>
                        Contactar
                      </Button>
                    </Footer>
                  </Form>
                )}
              </FormContainer>
              <Aside>
                <ContactIcon />
                <p>
                  También puedes contactar directamente a nuestro equipo de
                  expertos:
                </p>
                <ContactInfo>
                  <p>
                    <a href={`mailto:${mailto}`}>
                      <EmailSharp />
                      {mailto}
                    </a>
                  </p>
                  <p>
                    <a href={`tel:${phoneNumberUrl}`}>
                      <PhoneSharp />
                      {phoneNumberText}
                    </a>
                  </p>
                </ContactInfo>
              </Aside>
            </ContactWrapper>
          );
        }}
      />
    );
  }
}
