import React from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import theme, { animation, color } from '../../theme';
import { Header, Footer, ErrorBoundary } from '../../components';
import Helmet from 'react-helmet';
import config from '../../../config';

const GlobalStyle = createGlobalStyle`
  a {
    text-decoration: none;

    &:hover {
      color: ${color.secondary};
    }
  } 

  :selection {
    color: ${color.primaryLightest};
    background: ${color.secondary};
  }
`;

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  flex: 1;
  background: ${color.lightest};
  animation-name: animOpacity;
  animation-duration: ${animation.duration.short};

  @keyframes animOpacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <PageWrapper>
      <Helmet
        defaultTitle={config.site.title}
        link={[
          {
            rel: 'apple-touch-icon',
            href: '/apple-touch-icon.png'
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: '/favicon-32x32.png'
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: '/favicon-16x16.png'
          },
          {
            rel: 'mask-icon',
            href: '/safari-pinned-tab.svg',
            color: '#fe7f00'
          }
        ]}
        meta={[
          {
            name: 'apple-mobile-web-app-title',
            content: 'Demax'
          },
          {
            name: 'application-name',
            content: 'Demax'
          },
          {
            name: 'msapplication-TileColor',
            content: '#fe7f00'
          },
          {
            name: 'theme-color',
            content: '#fe7f00'
          }
        ]}
      />
      <GlobalStyle />
      <Header />
      <ErrorBoundary>
        <Main>{children}</Main>
      </ErrorBoundary>
      <Footer />
    </PageWrapper>
  </ThemeProvider>
);

export default Layout;
