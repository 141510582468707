import React from 'react';

function asyncComponent(getComponent, loadingComponent) {
  return class AsyncComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = { component: null };
    }

    componentDidMount() {
      if (!this.state.component) {
        getComponent().then(component => {
          this.setState({ component });
        });
      }
    }
    render() {
      const { component: Comp } = this.state;
      const { className } = this.props;
      if (Comp) {
        return <Comp {...this.props} />;
      }
      return loadingComponent || <div className={className}>Loading...</div>;
    }
  };
}

export default asyncComponent;
