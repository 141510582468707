import React from 'react';
import styled from 'styled-components';
import NavItem from './NavItem';
import { media } from '../../theme';

const Nav = styled.nav`
  position: relative;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;
  padding: 0 ${props => props.theme.spacing.small};

  ${media.tablet`     
    justify-content: flex-end;
    bottom: unset;
    top: 0;
  `}

  ${media.desktop`
    border-top: none
    background: transparent;
    display: flex;
    justify-content: flex-end;
    padding-left: 50px;
    transition: none;
  `}
`;

export default ({ items }) => {
  return (
    <Nav>
      {items &&
        items.length > 0 &&
        items.map((item, index) => (
          <NavItem key={`${item.name}${index}`} item={item} />
        ))}
    </Nav>
  );
};
