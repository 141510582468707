import React from 'react';
import styled from 'styled-components';
import Label from './Label';

const Input = styled.input.attrs(props => ({
  type: props.type ? props.type : 'text'
}))`
  padding-top: 2vh;
  margin-bottom: 2vh;
  transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
    background: rgba(0, 0, 0, 0.01);
  }
`;

export default props => {
  const { children, reversed, ...restProps } = props;
  return (
    <Label htmlFor={restProps.id}>
      {reversed ? (
        <React.Fragment>
          <Input {...restProps} />
          {children}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {children}
          <Input {...restProps} />
        </React.Fragment>
      )}
    </Label>
  );
};
