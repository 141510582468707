import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { spinalCase } from '../../utils';
import { color } from '../../theme';

const MetaWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 1vmin 0 3vmin 0;

  .tag {
    background: ${color.lightgrey};
    border-radius: 3px 0 0 3px;
    color: ${color.darkest};
    display: inline-block;
    height: 26px;
    line-height: 26px;
    padding: 0 20px 0 23px;
    position: relative;
    margin: 0 20px 10px 0;
    text-decoration: none;
    font-size: 0.7rem;
    font-weight: 300;
  }

  .tag:before {
    background: ${color.lightest};
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    content: '';
    height: 6px;
    left: 10px;
    position: absolute;
    width: 6px;
    top: 10px;
  }

  .tag:after {
    background: ${color.lightest};
    border-bottom: 13px solid transparent;
    border-left: 10px solid ${color.lightgrey};
    border-top: 13px solid transparent;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
  }

  .tag:hover {
    background-color: ${color.grey};
    color: white;
  }

  .tag:hover:after {
    border-left-color: ${color.grey};
    color: ${color.secondary};
  }
`;

export default props => {
  const { category, service } = props;

  return (
    <MetaWrapper>
      {service && (
        <Link className="tag" to={`/clase/${spinalCase(service)}`}>
          {service}
        </Link>
      )}
      {category && (
        <Link className="tag" to={`/categoria/${spinalCase(category)}`}>
          {category}
        </Link>
      )}
    </MetaWrapper>
  );
};
