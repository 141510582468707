import React from 'react';
import styled from 'styled-components';
import { media } from '../../theme';

const HeroSection = styled.section`
  color: ${props => props.theme.color.lightest};
  position: relative;
  ${props => (props.scale ? `overflow: hidden;` : '')}

  &:before {
    background: linear-gradient(
        0deg,
        ${props => props.theme.color.primary},
        ${props => props.theme.color.primary}CC 20%,
        ${props => props.theme.color.primaryLighter}CC 100%
      ),
      url(${props => props.backgroundMobile});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    ${props =>
      props.scale
        ? `animation: grow 40s linear 10ms infinite;
          transition: all 0.2s ease-in-out;`
        : ''}
  }

  @keyframes grow {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
  }

  ${media.tablet`
    &:before{
      background: linear-gradient(
        0deg,
        ${props => props.theme.color.primary},
        ${props => props.theme.color.primary}CC 20%,
        ${props => props.theme.color.primaryLighter}CC 100%
      ),
      url(${props => props.backgroundTablet});
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  `}

  ${media.desktop`
    &:before {
      background: linear-gradient(
        0deg,
        ${props => props.theme.color.primary},
        ${props => props.theme.color.primary}CC 20%,
        ${props => props.theme.color.primaryLighter}CC 100%
      ),
      url(${props => props.backgroundDesktop});
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  `}
`;

export default class HeroWrapper extends React.Component {
  render() {
    const {
      backgroundMobile,
      backgroundTablet,
      backgroundDesktop,
      children
    } = this.props;

    return (
      <HeroSection
        backgroundMobile={backgroundMobile}
        backgroundTablet={backgroundTablet}
        backgroundDesktop={backgroundDesktop}
        {...this.props}
      >
        {children()}
      </HeroSection>
    );
  }
}
