import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import { color, media } from '../../theme';
import { Share } from '../Common';
import config from '../../../config';

const query = graphql`
  query DetailedFooter {
    detailFooterLeft: markdownRemark(
      fileAbsolutePath: { regex: "*/detailed-footer--left/" }
    ) {
      id
      html
    }
    detailFooterRight: markdownRemark(
      fileAbsolutePath: { regex: "*/detailed-footer--right/" }
    ) {
      id
      html
    }
    bgHeroDetailedFooter: imageSharp(
      fluid: { originalName: { regex: "/hero-detailed-footer_2/" } }
    ) {
      resize(width: 800, cropFocus: CENTER, quality: 90) {
        src
      }
    }
  }
`;

const Wrapper = styled.div`
  margin: 0 6vw;
  min-height: 80vh;
  border-bottom: 1px solid ${color.lightest};
  font-weight: 300;
  padding-bottom: 2rem;
  position: relative;
`;

const ImageContainer = styled.div`
  background: url(${props => props.background});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 50vmin;
  position: relative;
  bottom: 6vmin;

  ${media.tablet`    
    width: 60%;
  `}
`;

const LeftSection = styled.div`
  li {
    margin-bottom: 0.2rem;
  }

  ${media.tablet`    
    width: 60%;
  `}
`;
const RightSection = styled.div`
  ${media.tablet`    
    position: absolute;
    right: 0;
    top: 8vmax;
    width: 32%;
  `}
`;

const ShareSection = styled.div`
  ${media.tablet`    
    position: absolute;
    right: 0;
    bottom: 20vmin;
    width: 32%;
  `}
`;

const DetailedFooter = () => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const {
          detailFooterLeft: { html: htmlLeft },
          detailFooterRight: { html: htmlRight },
          bgHeroDetailedFooter: {
            resize: { src: detailedFooterImage }
          }
        } = data;

        const post = {
          fields: { slug: '/' },
          frontmatter: { title: config.site.titleShort },
          excerpt: config.site.description
        };

        return (
          <Wrapper>
            <ImageContainer background={detailedFooterImage} />
            <LeftSection dangerouslySetInnerHTML={{ __html: htmlLeft }} />
            <RightSection dangerouslySetInnerHTML={{ __html: htmlRight }} />
            <ShareSection>
              <Share text="¡Comparte nuestra página!" post={post} />
            </ShareSection>
          </Wrapper>
        );
      }}
    />
  );
};

export default DetailedFooter;
