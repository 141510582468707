import React from 'react';
import styled from 'styled-components';

const HtmlWrapper = styled.div`
  margin-bottom: 4vmax;

  blockquote {
    font-weight: 300;
    background: ${props => props.theme.color.light};
    padding: 2vmax;
    text-align: center;
  }
`;

export default ({ html }) => {
  return <HtmlWrapper dangerouslySetInnerHTML={{ __html: html }} />;
};
