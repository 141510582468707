/* eslint-disable max-len */
module.exports = {
  site: {
    title: 'Demax | Desempeño Máximo',
    // Short title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
    titleShort: 'Demax',
    // Website description used for RSS feeds/meta description tag.
    description: 'Desempeño Máximo para Profesionales',
    url: 'https://www.demax.mx',
    // Logo used for SEO and manifest.
    image: 'preview.jpg',
    language: 'es',
    // Copyright string for the footer of the website
    copyright:
      '© 2021 - Demax | Desempeño Máximo. Todos los derechos reservados',
    errorMessage:
      'Ups! hubo un error inesperado, por favor intentalo más tarde o actualiza la página.',
    // Used for opengraph metadata
    author: {
      name: 'Demax',
      twitterAccount: 'demaxmx'
    }
  },
  manifest: {
    name: 'Demax | Desempeño Máximo',
    shortName: 'Demax',
    startUrl: '/index.html',
    // Used for background color on WPA splash screen
    backgroundColor: '#FE7F00',
    // Used for progress theme and browser colors for WPA
    themeColor: '#FE7F00',
    display: 'standalone'
  },
  navigationMenu: [
    {
      name: 'Inicio',
      url: '/',
      icon: 'Home'
    },
    {
      name: 'Servicios',
      url: '/servicios',
      icon: 'Business'
    },
    {
      name: 'Eventos',
      url: '/eventos',
      icon: 'Event'
    },
    {
      name: 'Contacto',
      url: '/contacto',
      icon: 'Phone'
    },
    {
      name: '',
      url: 'mailto:contacto@demax.mx',
      icon: 'Email'
    }
  ],
  dedicatedPostMenu: [
    {
      name: '+52 (81) 2894-5928',
      href: 'tel:8128945928',
      icon: 'Phone'
    },
    {
      name: '',
      url: 'https://wa.me/5218128945928',
      icon: 'Whatsapp',
      target: '_blank'
    },
    {
      name: '',
      url: 'mailto:contacto@demax.mx',
      icon: 'Email'
    }
  ],
  socialLinks: [
    {
      name: 'Twitter',
      url: 'https://twitter.com/demaxmx',
      icon: 'twitter'
    },
    {
      name: 'Facebook',
      url: 'https://facebook.com/demaxmx',
      icon: 'facebook'
    },
    {
      name: 'Whatsapp',
      url: 'https://wa.me/5218128945928',
      icon: 'whatsapp'
    }
  ],
  contactInfo: {
    phoneNumberText: '+52 (81) 2894-5928',
    phoneNumberUrl: '8128945928',
    mailto: 'contacto@demax.mx',
    location: 'Monterrey, Nuevo León.'
  },
  slider: {
    // Autoplay interval in milliseconds
    autoplay: 7000,
    slides: [
      {
        title: 'Capacitación para lograr cambios positivos',
        description: `Asesoría, cursos y talleres que generan acciones positivas para tu negocio`,
        buttonPrimary: {
          text: 'Comenzar',
          url: '/contacto'
        },
        buttonSecondary: {
          text: 'Conocer más',
          url: '/servicios'
        }
      },
      {
        title: 'Optimización de procesos y operaciones',
        description: `Desarrollamos proyectos de mejora, implementando sistemas de trabajo para incrementar la rentabilidad de la empresa`,
        buttonPrimary: {
          text: 'Comenzar',
          url: '/contacto'
        },
        buttonSecondary: {
          text: 'Conocer más',
          url: '/servicios'
        }
      },
      {
        title: 'Solución a medida de tus necesidades',
        description: `Con enfoque en los objetivos de la organización, nos adaptamos a requerimientos específicos`,
        buttonPrimary: {
          text: 'Comenzar',
          url: '/contacto'
        },
        buttonSecondary: {
          text: 'Conocer más',
          url: '/servicios'
        }
      }
    ]
  },
  services: {
    order: ['Liderazgo', 'Sensibilización', 'Estrategia', 'Asesoría']
  },
  testimonials: [
    // {
    //   company: 'Talleres del Norte',
    //   companyLogo: 'company1', // Este nombre debe de coincidir con el nombre de la imagen dentro de la carpeta images/testimonials
    //   headline: '20% de reducción de absentismo',
    //   testimonial:
    //     'Nos ayudaron mucho a mejorar la cultura organizacional de nuestra empresa, nuestros empleados han notado un cambio importante...',
    //   picture: 'testimonial1', // Este nombre debe de coincidir con el nombre de la imagen dentro de la carpeta images/testimonials
    //   name: 'Juan Pablo Rodriguez',
    //   jobTitle: 'Gerente de Operaciones'
    // },
    // {
    //   company: 'Maderas Finas Monterrey',
    //   headline: 'Notable mejora en la cultura organizacional del departamento',
    //   testimonial:
    //     'Nos ayudaron mucho a mejorar la cultura organizacional de nuestra empresa, nuestros empleados han notado un cambio importante... los indicadores han mostrado un aumento de 10% en tan solo dos meses despues de implementar las estrategias aprendidas.',
    //   name: 'Ana Maria Sanchez',
    //   picture: 'mujer',
    //   jobTitle: 'Superintendente de Embarques'
    // }
  ]
};
