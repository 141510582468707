exports.spinalCase = str => {
  return str
    .split(/\s|_|(?=[A-Z])/)
    .join('-')
    .toLowerCase();
};

exports.groupBy = (arr, propName) => {
  return arr.reduce((groups, item) => {
    const val = item[propName];
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});
};

exports.encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

exports.getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
