import { css } from 'styled-components';

export const color = {
  primary: '#FE7F00',
  primaryLighter: '#FF9932',
  primaryLightest: '#FFB266',
  primaryDarker: '#CC6600',
  primaryDarkest: '#994C00',
  secondary: '#17242E',
  secondaryLighter: '#304B60',
  secondaryLightest: '#497393',
  secondaryDarker: '#0D1F2D',
  secondaryDarkest: '#041B2D',
  light: '#f2f2f2',
  lighter: '#fafafa',
  lightest: '#FFFFFF',
  lightgrey: '#eeeeee',
  grey: '#8c8c8c',
  dark: '#262626',
  darker: '#0d0d0d',
  darkest: '#000000',
  twitter: '#1da1f2',
  facebook: '#3b5998',
  whatsapp: '#25D366',
  linkedin: '#0077b5'
};

export const font = {
  family: {
    header: `'Montserrat', 'Georgia', 'serif'`,
    body: `'Roboto', 'Helvetica', 'sans-serif'`
  },
  color: {
    primary: color.light,
    secondary: color.dark
  },
  size: {
    xxLarge: '3.6rem',
    xLarge: '2.4rem',
    large: '1.8rem',
    medium: '1.3rem',
    default: '1rem',
    small: '0.75rem'
  }
};

export const spacing = {
  xLarge: '12vw',
  large: '8vw',
  medium: '4vw',
  small: '2vw',
  xSmall: '1.5vw'
};

export const menu = {
  height: {
    default: '80px',
    fixed: '60px'
  }
};

export const animation = {
  duration: {
    long: '900ms',
    medium: '600ms',
    short: '300ms'
  }
};

export const screenSizes = {
  desktop: 1280,
  tablet: 768,
  phone: 576
};

// Iterate through the sizes and create a media template
export const media = Object.keys(screenSizes).reduce(
  (accumulator, sizeLabel) => {
    const emSize = screenSizes[sizeLabel] / 16;
    accumulator[sizeLabel] = (...args) => css`
      @media (min-width: ${emSize}em) {
        ${css(...args)};
      }
    `;

    return accumulator;
  },
  {}
);
