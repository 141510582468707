import React from 'react';
import styled from 'styled-components';
import { color, font, media } from '../../theme';
import Img from 'gatsby-image';

const Item = styled.section`
  margin: 0.8rem 0;
  background: ${color.lightest};
  box-shadow: rgba(0, 0, 0, 0.04) 1px 1px 2px 2px;
  transition: all 0.3s ease-out;

  & h4 {
    font-family: ${font.family.body};
    color: ${color.primary};
    font-weight: 300;
    margin-bottom: 0.2rem;
    letter-spacing: 0.2px;
  }

  & p {
    font-size: ${font.size.small};
    color: ${color.grey};
    margin-bottom: 1rem;
  }

  img {
    transition: transform 0.3s ease-out 0s !important;
  }

  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.08) 2px 2px 3px 3px;

    & img {
      transform: scale(1.2);
    }

    & p {
      color: ${color.dark};
    }
  }

  ${media.tablet`
    width: 30%;
    flex: 0 0 auto;
    align-self: stretch;
    margin: 0;
  `};
`;

const TagsContainer = styled.div`
  font-size: ${font.size.small};
  margin-bottom: 0.2rem;
  color: ${color.grey};
`;

const ImageContainer = styled(Img)`
  height: 240px;
`;

const Details = styled.div`
  padding: 0.6rem;
  padding-top: 0.8rem;
`;

export default props => {
  const {
    showDescription,
    post: {
      node: {
        fields: { slug },
        excerpt: description,
        id,
        frontmatter: {
          title,
          service,
          category,
          cover: { childImageSharp }
        }
      }
    }
  } = props;

  return (
    <Item key={id}>
      <a href={`/servicio${slug}`}>
        <ImageContainer alt={title} fluid={childImageSharp.fluid} />
        <Details>
          <h4>{title}</h4>
          <TagsContainer>
            <span>{service}</span> | <span>{category}</span>
          </TagsContainer>
          {showDescription && <p>{description}</p>}
        </Details>
      </a>
    </Item>
  );
};
