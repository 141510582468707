import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const queryTestimonialImages = graphql`
  query QueryTestimonialImages {
    images: allFile(filter: { absolutePath: { regex: "/testimonials/" } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 2vmax;
  background: ${props => props.theme.color.secondaryLighter};
  margin-bottom: 3vmax;
`;

const Company = styled.h2`
  text-align: center;
  text-transform: uppercase;
  color: white;
  text-shadow: 2px 2px ${props => props.theme.color.secondary};
  margin-bottom: 1.4rem;
`;

const CompanyLogo = styled.div`
  margin: auto;
  width: 30vw;
  max-width: 150px;
  max-height: 80px;
  margin-bottom: 1.4rem;
`;

const Headline = styled.h4`
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin-left: 1vmax;
  margin-right: 1vmax;
  margin-bottom: 1rem;
  color: white;
`;

const Testimonial = styled.blockquote`
  background: ${props => props.theme.color.lightgrey} !important;
  border-left: 10px solid ${props => props.theme.color.grey};
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  letter-spacing: 0.5px;
  font-family: Cambria, 'Times New Roman', serif;
  margin: 0.8rem;
  margin-top: 0;
  padding: 0.4rem;

  ${props => props.theme.media.tablet`
    font-size: 18px;
  `};
`;

const Person = styled.section`
  display: flex;
  flex-flow: row nowrap;
  max-height: 6vh;
  margin: 0 4vw;
  align-items: center;
  justify-content: center;
  color: white;
`;

const Picture = styled.div`
  max-width: 200px;
  max-height: 80px;

  div {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
`;

const Name = styled.h6`
  margin: 0;
  margin-left: 1rem;
`;

const JobTitle = styled.span`
  font-size: 12px;
  margin-left: 1rem;
  letter-spacing: 0.5px;
`;

const renderImage = (file, altTitle = '') => {
  return <Img fluid={file.node.childImageSharp.fluid} alt={altTitle} />;
};

export default ({
  companyLogo,
  headline,
  testimonial,
  picture,
  name,
  jobTitle,
  company
}) => {
  return (
    <StaticQuery
      query={queryTestimonialImages}
      render={data => {
        const { images } = data;

        return (
          <Wrapper>
            {companyLogo ? (
              <CompanyLogo>
                {renderImage(
                  images.edges.find(image => image.node.name === companyLogo),
                  'testimonial company logo'
                )}
              </CompanyLogo>
            ) : (
              <Company>{company}</Company>
            )}
            <Headline>{headline}</Headline>
            <Testimonial>
              <p>
                <q>{testimonial}</q>
              </p>
            </Testimonial>
            <Person>
              {picture && (
                <Picture>
                  {renderImage(
                    images.edges.find(image => image.node.name === picture),
                    'testimonial person image'
                  )}
                </Picture>
              )}
              <Name>{name}</Name>
              <JobTitle>{jobTitle}</JobTitle>
            </Person>
          </Wrapper>
        );
      }}
    />
  );
};
