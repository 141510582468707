import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const HeroImage = styled(Img)`
  position: unset !important;
`;

export default ({ title, childImageSharp }) => {
  return (
    <React.Fragment>
      <HeroImage alt={title} fluid={childImageSharp.fluid} />
    </React.Fragment>
  );
};
