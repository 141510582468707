import React from 'react';
import styled from 'styled-components';
import { color } from '../../theme';

const Label = styled.label`
  color: ${color.lighter};
  display: block;

  & input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid ${color.grey};
  }

  & input[type='radio'] {
    width: auto;
    margin-right: 1vmax;
    cursor: pointer;
  }
`;
export default props => <Label {...props}>{props.children}</Label>;
