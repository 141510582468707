import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
import config from '../../../config';
import styled, { css } from 'styled-components';
import { color, media } from '../../theme';
import Facebook from '../../images/svg/facebook.svg';
import Whatsapp from '../../images/svg/whatsapp.svg';
import Twitter from '../../images/svg/twitter.svg';
import Linkedin from '../../images/svg/linkedin.svg';

const PostShareWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.horizontal &&
    css`
      ${media.tablet`
        flex-direction: row;
        justify-content: center;
      `}
    `}
`;

const ButtonsList = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  flex-flow: row nowrap;

  .SocialMediaShareButton {
    cursor: pointer;
    margin: 0 1px;
    background-color: ${color.lightest};
    width: 65px;
    height: 50px;
    transition: all 0.3s ease;

    &:hover {
      path {
        fill: ${color.lightest};
      }
    }

    &.SocialMediaShareButton--facebook:hover {
      background-color: ${color.facebook};
    }
    &.SocialMediaShareButton--twitter:hover {
      background-color: ${color.twitter};
    }
    &.SocialMediaShareButton--whatsapp:hover {
      background-color: ${color.whatsapp};
    }
    &.SocialMediaShareButton--linkedin:hover {
      background-color: ${color.linkedin};
    }
  }

  svg {
    width: 32px;
    display: block;
    margin: 9px auto;
  }

  ${props =>
    props.fixed &&
    css`
      display: none;

      ${media.desktop`
        display: flex !important;
        flex-flow: column nowrap;
        position: sticky;
        top: 15%;
      `}
    `}
`;

const ShareText = styled.h3`
  ${props =>
    props.horizontal &&
    css`
      margin: 0;
      margin-right: 3vmin;
    `}
`;

class Share extends React.Component {
  render() {
    const {
      text,
      post: {
        fields: { slug },
        frontmatter: { title },
        excerpt
      },
      fixed,
      horizontal,
      ...restOfProps
    } = this.props;

    const postUrl =
      slug !== '/' ? `${config.site.url}/servicio${slug}` : config.site.url;

    return (
      <PostShareWrapper horizontal={horizontal} {...restOfProps}>
        {text && <ShareText horizontal={horizontal}>{text}</ShareText>}
        <ButtonsList fixed={fixed}>
          <FacebookShareButton
            url={postUrl}
            quote={`${title} - ${excerpt}`}
            additionalProps={{
              'aria-label': 'Facebook share'
            }}
          >
            <Facebook />
          </FacebookShareButton>
          <TwitterShareButton
            url={postUrl}
            title={title}
            additionalProps={{
              'aria-label': 'Twitter share'
            }}
          >
            <Twitter />
          </TwitterShareButton>
          <WhatsappShareButton
            url={postUrl}
            additionalProps={{
              'aria-label': 'Whatsapp share'
            }}
          >
            <Whatsapp />
          </WhatsappShareButton>
          <LinkedinShareButton
            url={postUrl}
            additionalProps={{
              'aria-label': 'LinkedIn share'
            }}
          >
            <Linkedin />
          </LinkedinShareButton>
        </ButtonsList>
      </PostShareWrapper>
    );
  }
}

export default Share;
