import React from 'react';
import { Link } from 'gatsby';
import Nav from './Nav';
import styled from 'styled-components';
import Logo from '../../images/svg/demax.svg';
import { media } from '../../theme';
import { Location } from '@reach/router';
import config from '../../../config';

const Header = styled.header`
  width: 100%;
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  justify-content: space-between;
  padding: 2vmin 2vmax;
  transition: all 0.3s ease-out;
  position: fixed;
  top: 0;
  z-index: 100;
  height: ${props => props.theme.menu.height.default};

  &.fixed {
    height: ${props => props.theme.menu.height.fixed};
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: ${props => props.theme.color.primary};
    background-color: ${props => props.theme.color.primary}f2;
    box-shadow: 0 0.0625em 0.5em rgba(0, 0, 0, 0.2);
  }

  &.subpage {
    position: sticky;
  }

  ${media.tablet`
    padding: 4vmin 4vmax;
    height: 140px;
  `};

  ${media.desktop`
    padding: 8vmin 8vmax;
  `};
`;

const LogoWrapper = styled.div`
  z-index: 99;
  flex: 1 0 auto;
  max-width: 150px;
  min-width: 100px;

  .fixed & svg {
    width: 140px;
  }

  svg {
    vertical-align: sub;
  }

  ${media.tablet`
    max-width: 220px;
    min-width: 140px;

    .fixed & svg {
      width: 150px;
    }
  `};
`;

export default class extends React.Component {
  state = {
    fixed: false,
    isScrolling: false
  };

  componentDidMount() {
    window.onscroll = this.onScroll;
  }

  componentWillUnmount() {
    window.onscroll = null;
  }

  onScroll = () => {
    if (!this.state.isScrolling) {
      window.requestAnimationFrame(this.handleHeaderVisibility);
    }
    this.setState({ isScrolling: true });
  };

  handleHeaderVisibility = () => {
    const scrollPercentage = +(window.scrollY * 100) / window.screen.height;
    if (scrollPercentage >= 5) {
      this.setState({ fixed: true });
    } else {
      this.setState({ fixed: false });
    }
    this.setState({ isScrolling: false });
  };

  getHeaderClasses = location => {
    const isHomePage = location.pathname === '/';
    if (!isHomePage) {
      return 'fixed subpage';
    }

    if (this.state.fixed) {
      return 'fixed';
    }
  };

  renderNavItems = location => {
    if (location.pathname.includes('/servicio/')) {
      return <Nav items={config.dedicatedPostMenu} />;
    }

    return <Nav items={config.navigationMenu} />;
  };

  render() {
    return (
      <React.Fragment>
        <Location>
          {({ location }) => (
            <Header className={this.getHeaderClasses(location)}>
              <LogoWrapper>
                <Link to="/">
                  <Logo />
                </Link>
              </LogoWrapper>
              {this.renderNavItems(location)}
            </Header>
          )}
        </Location>
      </React.Fragment>
    );
  }
}
