import React from 'react';
import styled from 'styled-components';
import { color } from '../../theme/variables';
import { Input, Button } from '../Common';
import { graphql, StaticQuery } from 'gatsby';
import { encode } from '../../utils';

const queryFormMessages = graphql`
  query CTAForm {
    successMessage: markdownRemark(
      fileAbsolutePath: { regex: "*/success-message/" }
    ) {
      id
      html
    }
    failMessage: markdownRemark(
      fileAbsolutePath: { regex: "*/fail-message/" }
    ) {
      id
      html
    }
  }
`;

const ContactWrapper = styled.section``;

const Form = styled.form`
  font-weight: 300;
  margin: 0;
`;

const InputsContainer = styled.div`
  input {
    padding-top: 2vh;
    margin-bottom: 2vh;
    color: ${color.lightest};
  }
`;

const Fieldset = styled.div`
  border: 0;
  margin-bottom: 2vmin;
  display: flex;
  flex-flow: row nowrap;

  label {
    margin-right: 6vmin;
  }
`;

const Header = styled.header`
  h3 {
    margin-bottom: 2vmin;
  }
`;

const Footer = styled.footer`
  button {
    background: ${color.primary};
    color: ${color.lightest};
    padding: 2vmin 4vmax;
    width: 100%;

    &:hover {
      background: ${color.primaryLightest};
      border-color: ${color.primaryLightest};
    }
    :active {
      background: ${color.primaryDarkest};
      border-color: ${color.primaryDarkest};
    }
  }
`;

const MessageContainer = styled.div``;

export default class CTAForm extends React.Component {
  state = {
    isSubmitted: false,
    isError: false
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        email: form.elements.email.value,
        contactType: form.elements.contactType.value,
        service: form.elements.service.value
      })
    })
      .then(() => this.handleSuccess())
      .catch(error => this.handleError(error));
  };

  handleSuccess = () => {
    this.setState({ isSubmitted: true });
  };

  handleError = error => {
    console.error(error);
    this.setState({ isError: true });
  };

  resetForm() {
    this.setState({ isSubmitted: false, isError: false });
  }

  render() {
    const { isSubmitted, isError } = this.state;

    return (
      <StaticQuery
        query={queryFormMessages}
        render={data => {
          const {
            successMessage: { html: htmlSuccess },
            failMessage: { html: htmlFail }
          } = data;

          return (
            <ContactWrapper>
              {isError ? (
                <MessageContainer
                  dangerouslySetInnerHTML={{ __html: htmlFail }}
                />
              ) : isSubmitted ? (
                <MessageContainer
                  dangerouslySetInnerHTML={{ __html: htmlSuccess }}
                />
              ) : (
                <Form
                  name="servicio"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                >
                  <Header>
                    <h3>¡ Sólo un paso hacia el cambio !</h3>
                  </Header>
                  <InputsContainer>
                    <input type="hidden" name="form-name" value="contact" />
                    <input
                      type="hidden"
                      name="service"
                      id="service"
                      value={this.props.title}
                    />
                    <Input
                      name="email"
                      id="email"
                      placeholder="Correo electrónico"
                      type="email"
                      required
                    />
                    <Fieldset id="contactType">
                      <Input
                        name="contactType"
                        id="contactTypeEmpresarial"
                        type="radio"
                        value="empresarial"
                        reversed
                        required
                      >
                        Empresarial
                      </Input>
                      <Input
                        name="contactType"
                        id="contactTypePersonal"
                        type="radio"
                        value="personal"
                        reversed
                        required
                      >
                        Personal
                      </Input>
                    </Fieldset>
                  </InputsContainer>
                  <Footer>
                    <Button type="submit" onClick={() => {}}>
                      Comenzar
                    </Button>
                  </Footer>
                </Form>
              )}
            </ContactWrapper>
          );
        }}
      />
    );
  }
}
