import React from 'react';
import styled from 'styled-components';
import Label from './Label';

const Textarea = styled.textarea.attrs(({ rows }) => ({
  rows: rows || 3
}))`
  padding-top: 2vh;
  margin-bottom: 2vh;

  transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
    background: rgba(0, 0, 0, 0.01);
  }
`;
export default props => (
  <Label htmlFor={props.id}>
    <Textarea {...props} />
  </Label>
);
