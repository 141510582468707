import React from 'react';
import styled from 'styled-components';
import { media } from '../../theme';
import Button from '../Common/Button';
import Img from 'gatsby-image';

const Article = styled.article`
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  overflow: hidden;
  margin: 6px 0;

  ${media.tablet`
    flex-direction: row;
  `}
`;

const Aside = styled.aside`
  position: relative;
  height: 40vmax;

  .image-container {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.1) rotate(1deg);
    }
  }

  ${media.tablet`
    flex-basis: 50%;
    height: auto;
  `}
`;

const SectionWrapper = styled.section`
  padding: 2rem;

  ${media.tablet`
    padding: 6vmax;
    flex-direction: row;
    flex-basis: 60%;
    &.alt {
      flex-direction: row-reverse;
    }
  `}

  ${media.desktop`
    padding: 4vmax;
    margin-left: 1rem;
  `}
`;

const Header = styled.header`
  margin-bottom: 3rem;

  h1 {
    color: ${props => props.theme.color.primary};
  }
  h4 {
    color: ${props => props.theme.color.dark};
  }
`;

const SectionContent = styled.section`
  margin-bottom: 2rem;

  p {
    position: relative;
    &:first-of-type {
      &:before {
        content: '';
        position: absolute;
        height: 4px;
        background: ${props => props.theme.color.primary};
        width: 50px;
        top: -1.5rem;
      }
    }
  }
`;

export default props => {
  if (props.post === null) {
    return null;
  }

  const {
    buttonText = 'Leer Más',
    post: {
      excerpt: description,
      fields: { slug },
      frontmatter: {
        title,
        category,
        cover: { childImageSharp }
      }
    }
  } = props;

  const url = `/servicio${slug}`;

  return (
    <Article>
      <Aside>
        <Img
          className="image-container"
          alt={title}
          fluid={childImageSharp.fluid}
        />
      </Aside>
      <SectionWrapper>
        <Header>
          <h1>{title}</h1>
          <h4>{category}</h4>
        </Header>
        <SectionContent>
          <p>{description}</p>
        </SectionContent>
        <footer>
          <Button navigateTo={url}>{buttonText}</Button>
        </footer>
      </SectionWrapper>
    </Article>
  );
};
