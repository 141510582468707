import React from 'react';
import styled from 'styled-components';
import { color, media } from '../../theme';
import Facebook from '../../images/svg/facebook.svg';
import Whatsapp from '../../images/svg/whatsapp.svg';
import Twitter from '../../images/svg/twitter.svg';
import config from '../../../config';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const Footer = styled.footer`
  background: ${color.primary};
  color: ${color.lightest};
  font-size: 14px;
  font-weight: 300;
`;

const Container = styled.div`
  padding-top: 3vh;
  margin: 0 6vw;
`;

const Info = styled.div`
  display: flex;
  flex-flow: column nowrap;

  div {
    margin-bottom: 0.4rem;
    flex: 1 1 auto;
  }

  span {
    margin-right: 2vw;
    display: block;

    ${media.desktop`
      display: inline;
    `}
  }

  a {
    color: ${color.lightest};
  }

  ${media.tablet`    
    flex-flow: row nowrap;
  `}
`;

const RightsReserved = styled.div`
  ${media.tablet`    
    text-align: right;
  `}
`;

const SocialLinksList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  max-width: 150px;

  li {
    flex: 1;
    padding-right: 20px;
  }

  path {
    fill: ${color.lightest};
  }
`;

export default () => (
  <Footer>
    <Container>
      <Info>
        <div>
          <span>
            Correo.{' '}
            <b>
              <a href={`mailto:${config.contactInfo.mailto}`}>
                {config.contactInfo.mailto}
              </a>
            </b>
          </span>
          <span>
            Teléfono.{' '}
            <b>
              <a href={`tel:${config.contactInfo.phoneNumberUrl}`}>
                {config.contactInfo.phoneNumberText}
              </a>
            </b>
          </span>
          <div>{config.contactInfo.location}</div>
        </div>
        <RightsReserved>{config.site.copyright}</RightsReserved>
      </Info>
      <SocialLinksList>
        {config.socialLinks.map(socialLink => (
          <li key={socialLink.name}>
            <OutboundLink
              target="_blank"
              rel="noopener noreferrer"
              href={socialLink.url}
            >
              {
                {
                  Facebook: <Facebook />,
                  Twitter: <Twitter />,
                  Whatsapp: <Whatsapp />,
                  default: null
                }[socialLink.name]
              }
            </OutboundLink>
          </li>
        ))}
      </SocialLinksList>
    </Container>
  </Footer>
);
