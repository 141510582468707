import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { media } from '../../theme';
import {
  EmailSharp,
  EventSharp,
  BusinessCenterSharp,
  PhoneSharp,
  HomeSharp
} from '@material-ui/icons';
import Whatsapp from '../../images/svg/whatsapp.svg';

const StyledAnchor = styled.a`
  font-size: ${props => props.theme.font.size.small};
  color: ${props => props.theme.color.lightest};
  margin: 0 0.2rem;
  font-family: ${props => props.theme.font.family.body};

  span {
    display: none;
  }

  svg {
    vertical-align: text-bottom;
    width: 24px;
  }

  ${media.tablet`
    margin: 0 0.8rem;

    svg {
      vertical-align: top;
    }
    
    span {
      display: inline;
      padding-left: 0.2rem;
    }
  `}

  ${media.desktop`
    margin: 0 1.6rem;
  `}
`;

const CustomAnchor = styled.div`
  svg {
    path {
      fill: ${props => props.theme.color.lightest};
    }

    &:hover {
      path {
        fill: ${props => props.theme.color.secondary};
      }
    }
  }
`;

const StyledLink = styled(Link)`
  font-size: ${props => props.theme.font.size.small};
  color: ${props => props.theme.color.lightest}ee !important;
  letter-spacing: 1.5px;
  position: relative;
  text-decoration: none;
  padding: 0.2rem 0;
  margin: 0 0.2rem;

  &:before,
  &:after {
    position: absolute;
    transition: all 0.3s ease-out;
  }

  /* underbar */
  &:before {
    bottom: 2px;
    display: block;
    height: 2px;
    width: 0%;
    content: '';
    background-color: ${props => props.theme.color.lightest};
  }

  /* data-hover text */
  &:after {
    left: 0;
    top: 0;
    padding: 0.2rem 0;
    position: absolute;
    content: '';
    color: ${props => props.theme.color.lightest};
    white-space: nowrap;
    max-width: 0%;
    overflow: hidden;
  }

  &:hover:before,
  &.active :before {
    opacity: 1;
    width: 100%;
  }

  &:hover:after,
  &.active :after {
    max-width: 100%;
  }

  svg {
    display: unset;
    font-size: 22px;
    vertical-align: text-bottom;
  }

  span {
    display: none;
  }

  ${media.tablet`
    margin: 0 0.8rem;

    /* data-hover text */
    &:after {
      content: attr(data-hover);
    }

    svg {
      display: none;
    }

    span {
      display: unset;
    }
  `}

  ${media.desktop`
    margin: 0 1.6rem;
  `}
`;

export default ({ item: { name, url, icon, href, target } } = {}) => {
  const Icon = {
    Home: <HomeSharp />,
    Business: <BusinessCenterSharp />,
    Event: <EventSharp />,
    Phone: <PhoneSharp />,
    Email: <EmailSharp />,
    Whatsapp: (
      <CustomAnchor>
        <Whatsapp />
      </CustomAnchor>
    ),
    default: null
  }[icon];

  // Display anchor item
  if (href) {
    return (
      <StyledAnchor href={href}>
        {Icon}
        <span>{name}</span>
      </StyledAnchor>
    );
  }

  // Display anchor item with icon
  if (!name) {
    return (
      <StyledAnchor href={url} target={target}>
        {Icon}
      </StyledAnchor>
    );
  }

  // Display nav item
  return (
    <StyledLink
      data-hover={name}
      activeClassName="active"
      to={url}
      data-slug={url}
    >
      {Icon}
      <span>{name}</span>
    </StyledLink>
  );
};
