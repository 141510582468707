import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  margin: 6vmin 6vmax;
`;

const Main = ({ children }) => {
  return <Wrapper>{children()}</Wrapper>;
};

export default Main;
