import React from 'react';
import { Link } from 'gatsby';
import { spinalCase } from '../../utils';
import styled from 'styled-components';

const Wrapper = styled.ul`
  a {
    font-weight: 300;
    letter-spacing: 0.5px;
  }
`;

const List = props => {
  const { edges } = props;

  return (
    <Wrapper>
      {edges.map(edge => {
        const {
          node: {
            frontmatter: { title },
            fields: { slug }
          }
        } = edge;

        const url = `servicio${spinalCase(slug)}`;

        return (
          <li key={url}>
            <Link to={url}>{title}</Link>
          </li>
        );
      })}
    </Wrapper>
  );
};

export default List;
