import React from 'react';
import styled from 'styled-components';
import { color } from '../../theme';
import { navigate } from 'gatsby';

const Button = styled.button`
  display: inline-block;
  transition: all 0.3s ease;
  color: ${color.primary};
  border: 2px solid ${color.primary};
  padding: 0.5vmax 4vmax;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  background-color: transparent;
  letter-spacing: 2px;
  position: relative;
  z-index: 1;

  & :hover {
    color: ${color.lightest};
    background: ${color.primary};
  }
`;

export default ({ children, navigateTo, ...props }) => {
  function handleClick(url) {
    navigate(url);
  }

  return (
    <Button onClick={() => handleClick(navigateTo)} {...props}>
      {children}
    </Button>
  );
};
